<template>
  <span v-html="highlightedText" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import escapeRegExp from "lodash/escapeRegExp";

const props = defineProps({
  needle: {
    type: String,
    default: ""
  },
  content: {
    type: String,
    required: true
  },
  styles: {
    type: String,
    default: "font-bold search-item"
  }
});

const highlightedText = computed(() => {
  if (!props.needle) {
    return props.content;
  }
  const regExp = new RegExp(escapeRegExp(props.needle), "gi");
  return props.content.replaceAll(
    regExp,
    (needle: unknown) => `<span class="${props.styles}">${needle}</span>`
  );
});
</script>
